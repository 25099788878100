<template>
  <div class="kefuPages">
    <div class="header">客服帮助</div>

    <van-row class="text">
      <van-col span="24" class="t1">Hi~有什么可以帮您</van-col>
      <van-col span="24" class="t2">专属客服在线为您解答</van-col>
    </van-row>

    <van-row class="onLine">
      <van-col span="24" @click="toUrl(1)">
        <p class="t1">在线客服</p>
        <p>工作时间： 0：00~24：00</p>
      </van-col>
      <van-col span="24" @click="toUrl(2)">
        <p class="t1">电话客服</p>
        <p>工作时间： 7：30~22：00</p>
      </van-col>
    </van-row>

    <div class="downBtn" style="display: none;">
      <van-button round block size="large" @click="down">点击下载APP</van-button>
    </div>

    <van-popup value position="bottom" :overlay="false" :lock-scroll="false">
      <van-button type="primary" icon="chat-o" :url="kefu" color="#ff5348" block>咨询客服</van-button>
    </van-popup>
  </div>
</template>
<script>
  import {isWeixin} from '@/utils/utils'
  export default {
    name:'service',
    components: {},
    data () {
      return {
        isWeixin:0,
        kefu:'',
        app:''
      }
    },
    created(){
      this.isWeixin = isWeixin()?1:0
      if(sessionStorage.getItem('payConfig')){
        let payConfig = JSON.parse(sessionStorage.getItem('payConfig'))
        const {kefu_url,app_download_url} = payConfig
        this.kefu = kefu_url
        this.app = app_download_url
      }else{
        this.$router.push({path:'/paycoupon',query:{type:'taw'}})
      }
    },
    mounted(){

    },
    methods:{
      down(){
        // if(this.isWeixin){
        //   this.$toast.loading({
        //     message: '请从浏览器打开',
        //     forbidClick: true,
        //   })
        // }else{
          location.href=this.app
          // var u = navigator.userAgent
          // if(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1){//Android
          //   location.href='https://vkceyugu.cdn.bspapp.com/VKCEYUGU-6aa9669f-e4ab-4db6-b3cf-9a4673fd2bf4/02042627-e50e-4522-bb3d-c2fe2d8d0400.apk'
          // }else if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){//ios
          //   location.href='https://apps.apple.com/cn/app/1618362578'
          // }
        // }
      },
      toUrl(type){
        if(type == 1){
          location.href=this.kefu
        }else if(type == 2){
          location.href='tel:4006178158'
        }
      }
    }
  }

</script>
<style scoped lang="less">
  .kefuPages{
    .header{background:url('@{static-dir}kefu_header.png') no-repeat;background-size:100% auto;color:#fff;font-size:24px;text-align:center;padding-top:20px;height:260px;}
    .text{padding:0px 20px;
      .t1{color:#ff4d42;font-size:24px;}
      .t2{color:#ff7d71;margin-top:8px;}
    }
    .onLine{width:66%;margin:10px auto 0px;
      .van-col{background:#edf8ee;border:1px solid #52ba59;text-align:center;padding:10px 0px;margin:10px 0px;color:#52ba59;border-radius:8px;
        .t1{font-size:18px;font-weight:700;margin-bottom:5px;}
      }
    }
    .downBtn{width:66%;margin:20px auto 0px;
      .van-button{background-image:linear-gradient(to right,#ff7d70,#ff4a3f);color:#fff;-webkit-animation:btn_scalc 1s ease infinite;animation:down_scalc 1s ease infinite;}
    }
  }
  @keyframes down_scalc{
    0% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
    25% {
      -webkit-transform:scale(1.08);
      transform: scale(1.08);
    }
    50% {
      -webkit-transform:scale(1.04);
      transform: scale(1.04);
    }
    75% {
      -webkit-transform:scale(1.08);
      transform: scale(1.08);
    }
    100% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
  }

</style>
